import { Col, DatePicker, Radio, Row } from 'antd'
import styled from 'styled-components'
import { DATE_OPTION_VALUE } from '@constants/constData'
import { MSelect } from 'components/@atomics'
import { IFilterOptions } from 'components/formItems'
import MFormItemWrapper, { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import * as utilDate from '@libs/utils/utilDate'
import { useState } from 'react'
import moment from 'moment'
import locale from 'antd/lib/date-picker/locale/ko_KR'

export type MFormItemFilterDate2Props = MFormItemWrapperProps & {
  selectOptions: IFilterOptions
  recentDateOptions: IFilterOptions
  dateTypeBilling?: boolean
  initialValue?: { [key: string]: any } | null
  defaultRecentDateOption?: DATE_OPTION_VALUE
}

function MFormItemFilterDateWithInitialValue(props: MFormItemFilterDate2Props) {
  const {
    selectOptions,
    recentDateOptions,
    dateTypeBilling = false,
    initialValue = null,
    ...formItemWrapperProps
  } = props
  const overFiveValue: boolean = recentDateOptions.length > 5

  const index = recentDateOptions?.findIndex((v) => v.value === initialValue?.recentDateOptions)
  const defaultIndex =
    index >= 0
      ? index
      : props?.defaultRecentDateOption
      ? recentDateOptions?.findIndex((v) => v.value === props?.defaultRecentDateOption)
      : recentDateOptions.length - 1
  const lastRecentDateOptionValue = recentDateOptions[defaultIndex].value
  const [name, setName] = useState<string>(selectOptions[0].value as string)
  const [radioValue, setRadioValue] = useState<any>(lastRecentDateOptionValue)

  return (
    <StyledMFormItemWrapper label="조회 기간" {...formItemWrapperProps} shouldUpdate>
      {({ setFieldsValue }) => (
        <Row>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={5}>
                <MSelect
                  value={name}
                  onChange={(value: string) => {
                    setName(value)
                    setRadioValue('oneMonth')
                    setFieldsValue({
                      [name]: dateTypeBilling
                        ? utilDate.rangeBillingDate(DATE_OPTION_VALUE.ONE_MONTH)
                        : utilDate.rangeDate(DATE_OPTION_VALUE.ONE_MONTH),
                    })
                  }}
                  filterOptions={selectOptions as IFilterOptions}
                />
              </Col>
              <Col lg={overFiveValue ? 13 : 12} xl={overFiveValue ? 12 : 10} xxl={overFiveValue ? 8 : 7}>
                <StyledRadioGroup buttonStyle="solid" value={radioValue}>
                  {recentDateOptions.map(({ value, title }) => {
                    return (
                      <Radio.Button
                        key={JSON.stringify(value)}
                        value={value}
                        onClick={() => {
                          setRadioValue(value)
                          setFieldsValue({
                            [name]: dateTypeBilling
                              ? utilDate.rangeBillingDate(value as DATE_OPTION_VALUE)
                              : utilDate.rangeDate(value as DATE_OPTION_VALUE),
                          })
                        }}
                      >
                        {title}
                      </Radio.Button>
                    )
                  })}
                </StyledRadioGroup>
              </Col>
              <Col span={overFiveValue ? 6 : 7}>
                <StyledMFormItemWrapper name={name} noStyle>
                  <DatePicker.RangePicker
                    picker={dateTypeBilling && 'month'}
                    locale={locale}
                    onChange={(dateRange) => {
                      if (dateRange) {
                        if (dateTypeBilling) {
                          dateRange[0] = moment(dateRange[0]).startOf('month')
                          dateRange[1] = moment(dateRange[1]).endOf('month')
                        } else {
                          dateRange[0] = moment(dateRange[0]).startOf('day')
                          dateRange[1] = moment(dateRange[1]).startOf('day')
                        }
                      }
                      setRadioValue(undefined)
                    }}
                  />
                </StyledMFormItemWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </StyledMFormItemWrapper>
  )
}

const StyledMFormItemWrapper = styled(MFormItemWrapper)`
  margin-bottom: 16px;
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;
`

export default MFormItemFilterDateWithInitialValue
